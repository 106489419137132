import * as React from 'react';
import { useEffect, useLayoutEffect, useRef } from 'react';
import { graphql, PageProps } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from '@mdx-js/react';
import { userShortcodes } from '../../lib/userShortcodes';
import styled, { ThemeProvider } from 'styled-components';
import { NavigationBarWrapper, PageNavigationBar } from '../PageNavigationBar';
import { Sidebar } from '../Sidebar';
import { ContentWrapper } from '../content/Content';
import { HeroContent, HeroWrapper } from '../content/Hero';
import { DomainsGridWrapper } from '../content/DomainsGrid';
import { useLocaleContext } from '../../contexts/LocaleContext';
import Sticky from 'react-stickynode';
import { HeaderWrapper } from '../header';
import { ReadingProgress } from '../ReadingProgress';
import { useMountEffect } from '../../hooks/useMountEffect';
import { screenSize } from '../../styles/ScreenSize';
import { useLayoutContext } from '../../contexts/LayoutContext';
import { TopicPageQuery } from '../../generated/graphql-types';
import { SEO } from '../seo';

const SidebarWrapper = styled.div`
	grid-column: 2;
	grid-row: 1 / 3;
	-ms-grid-row-span: 2;
	overflow: hidden;

	${screenSize.sm} {
		display: none;
	}
`;

const SidebarHero = styled.div`
	display: flex;
	justify-content: flex-end;
	flex-flow: column;
	height: calc(100vh - 36px);

	img {
		max-width: 100%;
	}
`;

interface TopicProps {
	bgColor?: string;
	bgImgUrl?: string;
}

const Main = styled.main<TopicProps>`
	&& {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		-ms-grid-columns: 1fr 1fr;

		${screenSize.sm} {
			grid-template-columns: 1fr;
			-ms-grid-columns: 1fr;
		}
	}

	${SidebarHero} {
		background-color: ${(p) => p.bgColor};
	}

	${HeroWrapper} {
		grid-row: 1;
		grid-column: 1;
		background-color: ${(p) => p.theme.bgColor};
		min-height: calc(100vh - 36px);
		padding: var(--padding);

		${HeroContent} {
			padding: 24px 0;
			width: 100%;

			${screenSize.sm} {
				padding: 24px 0 18px 0;
			}

			h1 {
				margin-bottom: 2rem;
			}

			p:last-of-type {
				margin-bottom: 2rem;

				${screenSize.sm} {
					margin-bottom: 1.2rem;
				}
			}

			${screenSize.sm} {
				p {
					margin-right: 0;
				}
			}
		}
	}

	${ContentWrapper} {
		grid-row: 2;
		grid-column: 1;
	}

	${DomainsGridWrapper} {
		grid-row: 4;
		grid-column: 1/3;
		-ms-grid-column-span: 2;
	}

	${NavigationBarWrapper} {
		grid-row: 3;
		grid-column: 1/3;
	}
`;

export default function TopicTemplate({
	data: { current, images, meta },
	pageContext,
}: PageProps<TopicPageQuery, { translations: any; language: string }>) {
	const { setBreadcrumbs } = useLayoutContext();
	const targetMain = useRef<HTMLDivElement>(null);
	const sidebarTarget = useRef<HTMLDivElement>(null);
	const { setPageTranslations } = useLocaleContext();

	useEffect(() => {
		setPageTranslations(pageContext.translations);
	}, []);

	const stickyStyle = `
		position: fixed;
		left: 50%;
		right: 0;
	`;

	function updateFreeze() {
		// TODO: optimize by storing values

		const rightClassName = SidebarHero.toString().slice(1);
		const leftClassName = HeroWrapper.toString().slice(1);

		const rightElement = sidebarTarget.current?.getElementsByClassName(rightClassName)[0];
		const rightHeight = rightElement?.getBoundingClientRect().height || 0;

		const leftElement = targetMain.current?.getElementsByClassName(leftClassName)[0];
		const leftHeight = leftElement?.getBoundingClientRect().height || 0;

		const scroll = window?.scrollY || 0;
		const isSticky = scroll <= leftHeight - rightHeight && leftHeight > rightHeight;

		const nonStickyStyle = `
			position: relative;
			padding-top: ${Math.max(leftHeight - rightHeight, 0)}px;
		`;

		sidebarTarget.current?.setAttribute('style', isSticky ? stickyStyle : nonStickyStyle);
	}

	useMountEffect(() => {
		addEventListener('scroll', updateFreeze);
		return () => removeEventListener('scroll', updateFreeze);
	});

	useLayoutEffect(() => {
		requestAnimationFrame(() => window.scrollBy(0, -1));
	});

	const currentPage = {
		title: current?.frontmatter?.title || '',
		href: `/${current?.frontmatter?.slug}`,
	};

	useMountEffect(() => {
		setBreadcrumbs([currentPage]);
	});

	const currentTheme = {
		bgColor: current?.frontmatter?.background,
	};

	const currentImg = images.nodes.find((image: { name: string }) => image.name === current?.frontmatter?.heroimage);
	const metaImg = meta.nodes.find((image: { name: string }) => image.name === current?.frontmatter?.metaimage);

	return (
		<>
			<SEO title={current?.frontmatter?.title} image={metaImg?.publicURL} />
			<ReadingProgress target={targetMain} />
			<ThemeProvider theme={currentTheme}>
				<Main
					bgColor={current?.frontmatter?.background || 'white'}
					bgImgUrl={currentImg?.publicURL || ''}
					ref={targetMain}
				>
					<MDXProvider components={userShortcodes}>
						<MDXRenderer>{current?.body || ''}</MDXRenderer>
					</MDXProvider>
					<SidebarWrapper ref={sidebarTarget}>
						<SidebarHero>
							{currentImg?.publicURL && <img src={currentImg?.publicURL} alt={current?.frontmatter?.image_alt || ''} />}
						</SidebarHero>
						<Sticky top={HeaderWrapper.toString()} bottomBoundary={SidebarWrapper.toString()}>
							<Sidebar top={HeaderWrapper.toString()} />
						</Sticky>
					</SidebarWrapper>
					<PageNavigationBar currentPage={currentPage} />
				</Main>
			</ThemeProvider>
		</>
	);
}

export const pageQuery = graphql`
	query TopicPage($basename: String!, $language: String!) {
		current: mdx(fields: { basename: { eq: $basename }, locale: { eq: $language } }) {
			id
			body
			frontmatter {
				title
				slug
				background
				heroimage
				metaimage
				image_alt
			}
		}
		images: allFile(filter: { extension: { eq: "svg" } }) {
			nodes {
				name
				publicURL
			}
		}
		meta: allFile(filter: { extension: { eq: "png" } }) {
			nodes {
				name
				publicURL
			}
		}
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
	}
`;
