import * as React from 'react';
import { createRef, useEffect, useState } from 'react';
import { useSidebarContext } from '../contexts/SidebarContext';
import styled from 'styled-components';

interface Wrapper {
	topOffset: number;
}

const Wrapper = styled.div<Wrapper>`
	width: 100%;
	height: calc(100vh - ${(p) => p.topOffset || 0}px);
	background: #aaaaaa;
`;

interface Sidebar {
	top?: number | string;
}

export const Sidebar: React.FC<Sidebar> = ({ top, children }) => {
	const [offsetTop, setOffsetTop] = useState(0);
	const wrapperRef = createRef<HTMLDivElement>();
	const { currentElement } = useSidebarContext();

	useEffect(() => {
		if (typeof top === 'string') {
			const element = document.querySelector(top)?.getBoundingClientRect();
			if (element) {
				setOffsetTop(element.height);
			}
		} else if (typeof top === 'number') {
			setOffsetTop(top);
		}
	}, [top]);

	return (
		<Wrapper ref={wrapperRef} topOffset={offsetTop}>
			{children}
			{currentElement}
		</Wrapper>
	);
};
